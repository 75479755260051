.wrapper {
  height: 100vh;
  overflow: hidden;
  transition: 0.2s linear;
  opacity: 0;
  z-index: 100;
}

.link {
  color: white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: 0.2s linear;
  }
}

.navigation {
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--navbar-height);
}

.main {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    width: 50%;
    opacity: 0.5;
    animation: grow 2s forwards;
  }
}

.show {
  opacity: 1;
}

@keyframes grow {
  from {
    width: 50%;
  }
  to {
    width: 75%;
  }
}

.nfts {
  opacity: 1;
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  transform: translateY(-80px);
  z-index: 2;
  justify-content: center;
}

.card {
  border-radius: 12px;
  width: 300px;
  min-height: 400px;
  background-color: var(--navbar-bg);
  margin: 12px;
  margin-bottom: 32px;
}

.header {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 32px;
}

@keyframes fadeout {
  from {
    height: 100vh;
    opacity: 1;
  }
  to {
    height: 0vh;
    opacity: 0;
  }
}

.btnShine {
  position: absolute;
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #a0a0a0 0, white 10%, #a0a0a0 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s forwards;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  max-width: 600px;
  font-size: 36px;
  text-decoration: none;
  white-space: break-spaces;
}

.btnShine2 {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #a0a0a0 0, white 10%, #a0a0a0 20%);
  background-position: 0;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine2 6s forwards;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 36px;
  max-width: 500px;
  text-decoration: none;
}

.btnShine3 {
  position: absolute;
  top: 70%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #a0a0a0 0, white 10%, #a0a0a0 20%);
  background-position: 0;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine3 6s forwards;
  animation-delay: 11s;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 36px;
  max-width: 540px;
  text-decoration: none;
  white-space: break-spaces;
}

.cardImage {
  height: 75%;
  border-radius: 12px;

  & img {
    border-radius: 12px;
    animation: bounce;
    width: 100%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  color: var(--text);
  justify-content: center;
  align-items: center;
  height: 25%;
  padding: 0.25rem;
  padding-bottom: 2rem;

  & .price {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}

.modal {
  background: var(--modal-bg);
  width: 540px;
  padding: 2rem 4rem;

  & .desc {
    color: var(--text);
    display: block;
    margin-top: 1rem;
    font-weight: 500;
    text-align: center;
  }
}
.modalImage {
  display: block;
  width: 200px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}
.modalInput {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  outline: none;
  border: none;
  padding: 0rem 1rem;
  height: 48px;
  border-radius: 12px;
  background-color: var(--main-bg);
  color: var(--text);
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

@keyframes shine {
  0% {
    opacity: 0.5;
    background-position: 0;
  }
  10% {
    opacity: 1;
  }
  60% {
    background-position: 500px;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    background-position: 550px;
  }
}

@keyframes shine2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    background-position: 350px;
  }
}

@keyframes shine3 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    background-position: 0;
  }
  60% {
    background-position: 300px;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    background-position: 350px;
  }
}
