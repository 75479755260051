.wrapper {
  display: flex;
  align-items: center;
  min-height: calc(100vh - var(--navbar-height));
  justify-content: space-between;
}

.art {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
  min-height: 600px;
}

.artHeader {
  position: absolute;
  top: 0;
  left: 0;
  color: #0b0b0b;
  letter-spacing: 1px;

  & h2 {
    font-size: 32px;
    font-family: "Joan", serif !important;
  }
}

.swapWrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.box {
  background-color: var(--swap-bg);
  border-radius: 20px;
  width: 90%;
  margin: auto;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  color: var(--text);
}

.rowBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
}

.rowCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb {
  margin-top: 8px;
  margin-bottom: 8px;
}

.modal {
  width: 400px;
}

.inputWrapper {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  background-color: var(--main-bg);
  border-radius: 12px;
  position: relative;

  &.modal {
    margin-top: 1rem;
    height: 42px;
  }
}

.inputButtons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.inputButton {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  width: max-content !important;
  background-color: var(--swap-bg) !important;

  &.active {
    background-color: var(--swap-bg-dark) !important;
  }
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 28px;
  color: var(--text);
  font-weight: 500;

  &.modal {
    font-size: 18px !important;
  }
}

.label {
  display: flex;
  align-items: center;
  color: var(--label);
  font-size: 12px;

  & span {
    display: flex;
    align-items: center;
  }

  & svg {
    margin-left: 0.3rem;
  }
}

.header {
  font-weight: 600;
}

.balance {
  padding-right: 8px;
}

.tokenImage {
  display: flex;
  margin-right: 8px;

  & img {
    width: 24px;
  }
}

.inputToken {
  display: flex;
  align-items: center;
}

.inputTokenName {
  display: flex;
  font-weight: 500;
}

.modalHeader {
  font-size: 18px;
  display: block;
  margin-bottom: 2rem;
}

.formWrapper {
  & span {
    font-size: 12px;
    color: var(--label);
  }

  display: flex;
  width: 100%;
  flex-direction: column;
}

.swapButton {
  margin-top: 2rem;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sliderWrapper {
  display: flex;
  margin-top: 2rem;
  position: relative;
}

.thumb {
  box-shadow: var(--shadow1);
  min-width: 20px;
  min-height: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  background: #3acd90;
  user-select: none;
  border: 5px solid white;
  position: absolute;
  transform: translate(-4px, -16px);
  outline: none !important;

  &:focus {
    outline: none !important;
    display: none;
  }
}

.trackWrapper {
  display: flex;
  margin-top: 1.5rem;

  &:nth-child(2) {
    display: none;
  }
}

.trackActive {
  position: relative;
  height: 10px;
  background: #3acd90;
  border-radius: 24px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.trackPassive {
  position: relative;
  height: 10px;
  background: var(--main-bg);
  border-radius: 24px;
}

@media (max-width: 900px) {
  .wrapper {
    flex-direction: column;
  }
  .art {
    order: 2;
    width: 90%;
  }
  .swapWrapper {
    margin-top: 2rem;
    order: 1;
    width: 95%;
  }
  .box {
    width: 100%;
  }
}
