.wrapper {
  position: relative;
  min-height: 100vh;
  background-color: black;
  background-image: url(../../assets/images/embrio.png);
  background-position: 50%;
  background-image: cover;
  background-size: 75%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.rest {
  background-color: #6f162e;
  padding-top: 5rem;
  min-height: 100vh;
}

.quote {
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  white-space: nowrap;
}

.header {
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;

  & h1 {
    font-size: 48px;
    letter-spacing: 1px;
    text-align: center;
    font-family: "Joan", serif !important;
  }

  & span {
    text-align: center;
    color: white;
    letter-spacing: 0px;
    max-width: 420px;
    margin-bottom: 15rem;
  }
}
.leftText {
  margin-top: 3rem;
  color: white;
  max-width: 480px;
  margin-left: 120px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  line-height: 38px;
}

.rightText {
  margin-top: 3rem;
  color: white;
  max-width: 480px;
  text-align: right;
  margin-left: auto;
  margin-right: 120px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  line-height: 38px;
}

.landing {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;

  & img {
    width: 100%;
  }
}

.bottom {
  min-height: 80vh;
  background-color: black;
}

.tree {
  position: absolute;
  transform: translateY(-310px);
  width: 430px;
  top: 0;
  margin-left: auto;
  margin-right: auto;

  & img {
    position: absolute;
    width: 100%;
  }
}

.notVisible {
  opacity: 0;
  pointer-events: none;
}

.bottom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomHeader {
  margin-top: 4rem;
  color: #ffffff;
  opacity: 1;
  max-width: 320px;
  text-align: center;
}

@media (max-width: 992px) {
  .leftText {
    margin-left: 0px;
  }
  .rightText {
    margin-right: 0px;
  }
}
