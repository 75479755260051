.wrapper {
  position: relative;
  border-radius: 14px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.1s linear;

  &:hover {
    transform: scale(1.025);
    transition: 0.1s linear;
  }
  &.blue {
    background: linear-gradient(92.99deg, #01bffb 1.3%, #379aff 95.71%);
    box-shadow: inset -6px -4px 4px #016cdb, inset -2px 0px 21px #3e9afc,
      inset -30px -30px 60px #065ab0, inset -20px -20px 15px -12px #000000;
  }
  &.neutral {
    background: var(--neutral-button-bg);
  }

  &.pink {
    background: linear-gradient(91.79deg, #ff84ba 1.64%, #ed285f 97.77%);
    box-shadow: inset -7px -5px 5px #ff6a94, inset 10px 0px 16px #ff769c,
      inset -30px -30px 60px #ed285f, inset -20px -20px 15px -12px #000000;
  }

  &.ghost {
    background: transparent;
    &:hover {
      background: var(--neutral-button-bg);
    }
  }

  &.red {
    background: #6f162e;
    &:hover {
      background: #621529;
    }
  }

  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
  }
}

.text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  &.loading {
    opacity: 0;
    pointer-events: none;
  }

  &.blue {
    color: white;
  }
  &.pink {
    color: white;
  }
  &.neutral {
    color: var(--text);
  }
}

.fullwidth {
  width: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
