.art {
  width: 100%;
  max-height: 80vh;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  position: relative;

  & img {
    width: 100%;
    position: absolute;
  }
}

.notVisible {
  opacity: 0;
  pointer-events: none;
}
